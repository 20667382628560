import React from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Grid, 
  Container, 
  Paper,
  useTheme,
  Stack,
  Avatar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
  RocketLaunch, 
  AutoAwesome, 
  Terminal, 
  Cloud,
  Dashboard,
  Support,
  Settings,
  Api
} from '@mui/icons-material';
import { styled } from '@mui/system';

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  display: 'inline',
}));

const ServiceCard = ({ icon, title }) => {
  const navigate = useNavigate();
  
  return (
    <Paper elevation={0} sx={{ 
      p: 3,
      textAlign: 'center',
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 2,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        borderColor: 'primary.main'
      }
    }} onClick={() => navigate('/login')}>
      <Box sx={{ 
        width: 60, 
        height: 60, 
        bgcolor: 'primary.light', 
        borderRadius: '50%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        mb: 2
      }}>
        {React.cloneElement(icon, { sx: { fontSize: 30, color: 'primary.main' } })}
      </Box>
      <Typography variant="h6">{title}</Typography>
    </Paper>
  );
};

const HomePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const services = [
    {
      icon: <AutoAwesome />,
      title: "AI Automation"
    },
    {
      icon: <Terminal />,
      title: "Custom Software"
    },
    {
      icon: <Cloud />,
      title: "Cloud Solutions"
    },
    {
      icon: <RocketLaunch />,
      title: "Rapid Deployment"
    }
  ];

  return (
    <Box sx={{ minHeight: '100vh' }}>
      {/* Hero Section */}
      <Box sx={{ 
        pt: 15, 
        pb: 10,
        background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.grey[100]} 100%)`
      }}>
        <Container maxWidth="md">
          <Box sx={{ textAlign: 'center', mb: 6 }}>
            <Avatar
              sx={{ 
                width: 60, 
                height: 60, 
                bgcolor: 'primary.light', 
                color: 'primary.main',
                mb: 3,
                mx: 'auto'
              }}
            >
              HN
            </Avatar>
            <Typography variant="h3" component="h1" sx={{ 
              fontWeight: 700, 
              mb: 2,
              lineHeight: 1.2
            }}>
              HoiNeki <GradientText>Customer Portal</GradientText>
            </Typography>
            <Typography variant="h6" sx={{ 
              color: 'text.secondary',
              fontWeight: 400,
              maxWidth: 600,
              mx: 'auto'
            }}>
              Secure access to your modern software solutions and AI automation services.
            </Typography>
          </Box>
          
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={3}>
              <Button 
                fullWidth
                variant="contained" 
                size="large"
                sx={{ py: 2 }}
                startIcon={<Dashboard />}
                onClick={() => navigate('/login')}
              >
                Dashboard
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button 
                fullWidth
                variant="outlined" 
                size="large"
                sx={{ py: 2 }}
                startIcon={<Support />}
                onClick={() => navigate('/login')}
              >
                Support
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Services Section */}
      <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
        <Container maxWidth="md">
          <Typography variant="h5" sx={{ 
            fontWeight: 600, 
            mb: 4,
            textAlign: 'center'
          }}>
            Your Active Services
          </Typography>
          <Grid container spacing={3}>
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <ServiceCard {...service} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Quick Links */}
      <Box sx={{ py: 6 }}>
        <Container maxWidth="sm">
          <Paper elevation={0} sx={{ 
            p: 4,
            borderRadius: 3,
            border: '1px solid',
            borderColor: 'divider'
          }}>
            <Stack spacing={2}>
              <Button 
                variant="contained" 
                size="large" 
                fullWidth
                sx={{ py: 1.5 }}
                startIcon={<Api />}
                onClick={() => navigate('/login')}
              >
                API Access
              </Button>
              <Button 
                variant="outlined" 
                size="large" 
                fullWidth
                sx={{ py: 1.5 }}
                startIcon={<Settings />}
                onClick={() => navigate('/login')}
              >
                Account Settings
              </Button>
            </Stack>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default HomePage;