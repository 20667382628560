import { createTheme } from "@mui/material";

export const AppTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: "#1565C0",     // Deep blue (7.4:1 contrast on white)
      light: "#5E92F3",
      dark: "#003C8F",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#D84315",     // Warm orange-red (6.3:1 contrast)
      light: "#FF7043",
      dark: "#9F0000",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#D32F2F",     // Classic red (7:1 contrast)
      light: "#EF5350",
      dark: "#B71C1C",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FF8F00",     // Amber (4.7:1 contrast, meets AA)
      light: "#FFC046",
      dark: "#FF6F00",
      contrastText: "#000000",
    },
    success: {
      main: "#2E7D32",     // Green (6.1:1 contrast)
      light: "#4CAF50",
      dark: "#1B5E20",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#0277BD",     // Blue (7.1:1 contrast)
      light: "#58A5F0",
      dark: "#004C8C",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#FAFAFA",  // Off-white (soft on eyes)
      paper: "#FFFFFF",    // Pure white
    },
    text: {
      primary: "#212121",  // Near-black (15.9:1 contrast)
      secondary: "#424242", // Dark gray (11:1 contrast)
      disabled: "#9E9E9E",
    },
    divider: "#E0E0E0",    // Light gray divider
  },
  typography: {
    fontFamily: '"Inter", "Roboto", sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
      color: "#1565C0",    // Primary color for headings
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.6,
    },
    button: {
      textTransform: "none",
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: "8px 16px",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          border: "1px solid #E0E0E0", // Subtle border
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#BDBDBD", // Medium gray border
            },
            "&:hover fieldset": {
              borderColor: "#1565C0",  // Primary color on hover
            },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#1565C0",
          fontWeight: 500,
          textDecoration: "underline",
          "&:hover": {
            color: "#003C8F", // Darker primary
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1565C0",
          color: "#FFFFFF",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});