import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Container, 
  Paper, 
  Avatar,
  Divider,
  Link,
  useTheme
} from '@mui/material';
import { 
  Bolt, 
  Security, 
  Code, 
  Email, 
  LocationOn,
  Star
} from '@mui/icons-material';

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Paper elevation={0} sx={{
      p: { xs: 3, md: 4 },
      height: '100%',
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 4,
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 10px 30px rgba(0, 204, 255, 0.1)',
        borderColor: 'primary.main'
      }
    }}>
      <Box sx={{
        width: { xs: 50, md: 60 },
        height: { xs: 50, md: 60 },
        bgcolor: 'primary.light',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mb: 3
      }}>
        {React.cloneElement(icon, { sx: { fontSize: { xs: 25, md: 30 }, color: 'primary.main' } })}
      </Box>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 700 }}>{title}</Typography>
      <Typography variant="body1" color="text.secondary">{description}</Typography>
    </Paper>
  );
};

const TeamCard = ({ name, designation, description }) => {
  return (
    <Paper elevation={0} sx={{
      p: 3,
      height: '100%',
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 3,
      textAlign: 'center'
    }}>
      <Avatar sx={{ 
        width: { xs: 80, md: 100 },
        height: { xs: 80, md: 100 }, 
        mb: 3, 
        mx: 'auto',
        bgcolor: 'primary.light',
        color: 'primary.main',
        fontSize: { xs: 30, md: 40 }
      }}>
        {name.split(' ').map(n => n[0]).join('')}
      </Avatar>
      <Typography variant="h6" sx={{ fontWeight: 700 }}>{name}</Typography>
      <Typography variant="subtitle1" color="primary" sx={{ mb: 2 }}>{designation}</Typography>
      <Typography variant="body1" color="text.secondary">{description}</Typography>
    </Paper>
  );
};

const HoiNekiLanding = () => {
  const theme = useTheme();

  const features = [
    {
      icon: <Bolt />,
      title: "Lightning-Fast Delivery",
      description: "Enterprise-grade applications delivered at warp speed, powering lakhs of users across Bengaluru with seamless performance."
    },
    {
      icon: <Security />,
      title: "Military-Grade Reliability",
      description: "Built to withstand the toughest challenges, our solutions offer 99.99% uptime and scale effortlessly under pressure."
    },
    {
      icon: <Code />,
      title: "Elite Engineering",
      description: "A visionary trio crafting cutting-edge solutions that redefine what's possible in modern technology."
    }
  ];

  const team = [
    {
      name: "Ratul Doley",
      designation: "Software Engineer",
      description: "A passionate problem-solver, Ratul blends deep care for people with a vision for transformative technology."
    },
    {
      name: "Sailkhlum Narzary",
      designation: "IT Expert",
      description: "Driven by the next big disruption, Sailkhlum thrives on harnessing AI and emerging tech to shape a bold future."
    },
    {
      name: "Mr. Shiva Deva",
      designation: "Speed Coding Master",
      description: "A coding powerhouse, Shiva outpaces the competition, delivering brilliance at lightning speed."
    }
  ];

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      {/* Hero Section with Customer Portal Link */}
      <Box sx={{ 
        pt: { xs: 8, md: 12 },
        pb: 10,
        background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.grey[100]} 100%)`
      }}>
        <Container maxWidth="lg">
          <Box sx={{ textAlign: 'center' }}>
            <Typography 
              variant="h1" 
              component="h1" 
              sx={{ 
                fontSize: { xs: '4rem', sm: '6rem', md: '8rem' },
                lineHeight: 1.1,
                mb: 3,
                fontWeight: 900,
                letterSpacing: { xs: '0.02em', md: '0.04em' },
                color: 'primary.main',
              }}
            >
              HoiNeki
            </Typography>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 400,
                color: 'text.secondary',
                maxWidth: 700,
                mx: 'auto',
                fontSize: { xs: '1.25rem', md: '1.75rem' },
                mb: 4 // Added margin for spacing before link
              }}
            >
              Building the Future of Technology from Bengaluru
            </Typography>
            <Link 
              href="https://app.hoineki.com/login" 
              underline="hover" 
              sx={{ 
                fontSize: { xs: '1.1rem', md: '1.25rem' },
                fontWeight: 600,
                color: 'primary.main',
                display: 'inline-block',
                px: 3,
                py: 1,
                borderRadius: 2,
                transition: 'background 0.3s ease',
                '&:hover': {
                  backgroundColor: 'primary.light',
                  color: 'primary.dark'
                }
              }}
            >
              Access Customer Portal
            </Link>
          </Box>
        </Container>
      </Box>

      {/* Features Section */}
      <Box sx={{ py: { xs: 8, md: 10 }, bgcolor: 'background.paper' }}>
        <Container maxWidth="lg">
          <Grid container spacing={{ xs: 3, md: 4 }}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <FeatureCard {...feature} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Team Section */}
      <Box sx={{ py: { xs: 8, md: 10 }, background: theme.palette.grey[100] }}>
        <Container maxWidth="lg">
          <Typography 
            variant="h3" 
            sx={{ 
              textAlign: 'center', 
              mb: 8,
              fontWeight: 700,
              color: 'primary.main'
            }}
          >
            Meet the Team
          </Typography>
          <Grid container spacing={{ xs: 3, md: 4 }}>
            {team.map((member, index) => (
              <Grid item xs={12} md={4} key={index}>
                <TeamCard {...member} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Partnerships Section */}
      <Box sx={{ py: { xs: 8, md: 10 }, bgcolor: 'background.paper' }}>
        <Container maxWidth="md">
          <Paper elevation={0} sx={{ 
            p: { xs: 4, md: 6 }, 
            borderRadius: 4,
            border: '1px solid',
            borderColor: 'divider',
            textAlign: 'center',
            background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.grey[50]} 100%)`
          }}>
            <Star sx={{ fontSize: { xs: 40, md: 50 }, color: 'primary.main', mb: 3 }} />
            <Typography variant="h4" sx={{ fontWeight: 700, mb: 3 }}>
              Precision Partnerships
            </Typography>
            <Typography variant="body1" sx={{ 
              fontSize: { xs: '1rem', md: '1.2rem' },
              color: 'text.secondary',
              maxWidth: 700,
              mx: 'auto'
            }}>
              We team up with happy clients across Bengaluru who share our passion for building the future. Our collaborations thrive on clear vision, tight timelines, and a relentless drive for excellence.
            </Typography>
          </Paper>
        </Container>
      </Box>

      {/* Footer Contact Section */}
      <Box sx={{ py: { xs: 8, md: 10 }, background: theme.palette.grey[900], color: 'common.white' }}>
        <Container maxWidth="md">
          <Typography 
            variant="h3" 
            sx={{ 
              textAlign: 'center', 
              mb: 6,
              fontWeight: 700
            }}
          >
            Join the Future
          </Typography>
          <Grid container spacing={{ xs: 4, md: 6 }}>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <LocationOn sx={{ mr: 2, color: 'primary.main' }} />
                <Typography variant="body1">
                  21st Main Rd, 3rd C Cross<br />
                  BTM 2nd Stage, Bengaluru 560076
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Email sx={{ mr: 2, color: 'primary.main' }} />
                <Link href="mailto:r.doleyz1993@gmail.com" color="inherit" underline="hover">
                  r.doleyz1993@gmail.com
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <Box component="footer" sx={{ py: 4, textAlign: 'center' }}>
        <Container maxWidth="lg">
          <Divider sx={{ mb: 3 }} />
          <Typography variant="body1" color="text.secondary">
            © 2025 HoiNeki. All rights reserved.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default HoiNekiLanding;